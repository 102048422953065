import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"]
})
export class SignupComponent implements OnInit {
  //Variable declration
  signupForm: FormGroup;
  showBtn = true;
  isPasswordMatch = false;

  //Social media variables
  public user: SocialUser;
  public loggedIn: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.myapp.spinner.show();
    //Form Model Declaration with form validation for the fields
    this.signupForm = this.fb.group({
      country_code: ["+64", [Validators.required]],
      mobile_number: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ],
      date_of_birth: ["", [Validators.required]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
          )
        ]
      ],
      conf_pass: ["", [Validators.required]],
      terms: ["", [Validators.required]]
    });

    //Social Media Data receving
    this.authService.authState.subscribe(user => {
      this.user = user;
      this.loggedIn = user != null;

      //console.log("User data", this.user);

      //Call the API to register/login
      if (this.user != null) {
        this.myapp.spinner.show();
        const formData = new FormData();
        formData.append("email", this.user.email);
        formData.append("name", this.user.name);
        formData.append("sm_id", this.user.id);
        formData.append("platform", this.user.provider);
        formData.append("profile_image", this.user.photoUrl);

        this.api.callAPI(formData, "socialSignupLogin").subscribe(res => {
          if (res.status) {
            this.myapp.spinner.hide();

            //Store the user data in local storage
            window.localStorage.setItem("webUserLogin", "1");
            window.localStorage.setItem("webUserId", res.data.user_id);
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("webCustomerId", res.data.customer_id);
            window.localStorage.setItem("loginPlatform", res.data.platform);
            window.localStorage.setItem(
              "loginUserImage",
              res.data.profile_image
            );
            window.localStorage.setItem(
              "userNotificationCount",
              res.data.notification_count
            );
            this.myapp.loginUserImage = res.data.profile_image;
            this.myapp.userNotificationCount = res.data.notification_count;

            this.myapp.userMenu = true;
            this.myapp.showDropDownMenu = false;
            this.myapp.showMenuToSMUser = false;

            this.router.navigate(["../my-profile"]);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showError(res.message);
          }
        });
      }
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //form submit function
  onFormSubmit() {
    //this.myapp.hideAlerts();
    //console.log("Service Image:" + this.service_image.value);
    if (this.password.value != this.conf_pass.value) {
      this.isPasswordMatch = true;
      return false;
    }

    this.showBtn = false;
    this.api.callAPI(this.signupForm.value, "signup").subscribe(res => {
      if (res.status) {
        this.myapp.showSuccess(res.message);
        this.showBtn = true;

        //Store the OTP in local storage
        window.localStorage.setItem("otp", res.otp);
        window.localStorage.setItem("emailOtp", res.emailOtp);
        window.localStorage.setItem("registerUserId", res.user_id);

        this.router.navigate(["../otp-verification"]);
        //this.resetForm();
      } else {
        this.myapp.showError(res.message);
        this.showBtn = true;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.signupForm.reset();
  }

  //Getor - To access the fields directly in the form in HTML
  get country_code() {
    return this.signupForm.get("country_code");
  }
  get mobile_number() {
    return this.signupForm.get("mobile_number");
  }
  get email() {
    return this.signupForm.get("email");
  }
  get date_of_birth() {
    return this.signupForm.get("date_of_birth");
  }
  get password() {
    return this.signupForm.get("password");
  }
  get conf_pass() {
    return this.signupForm.get("conf_pass");
  }
  get terms() {
    return this.signupForm.get("terms");
  }

  //Social Media Functions
  signInWithGoogle(): void {
    console.log("gmail login");
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
