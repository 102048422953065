import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "../../../app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "../../../service/api.service";

@Component({
  selector: "app-current-email-verification",
  templateUrl: "./current-email-verification.component.html",
  styleUrls: ["./current-email-verification.component.css"]
})
export class CurrentEmailVerificationComponent implements OnInit {
  //Variable declration
  otpForm: FormGroup;
  emailForm: FormGroup;
  user_id: any = 0;
  otp: any = "";
  verification_type: any = "";
  user_verification_id: any = "";
  otpVerified = true;

  otpError = false;
  inputOtp: any = "";
  showOtpComponent = true;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "60px",
      height: "40px"
    }
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //If user tries to access without register
    if (!localStorage.getItem("user_verification_id")) {
      this.router.navigate(["/my-profile"]);
    }

    this.user_id = localStorage.getItem("webUserId");
    this.otp = localStorage.getItem("otp");
    this.user_verification_id = localStorage.getItem("user_verification_id");

    //Form Model Declaration with form validation for the fields
    this.otpForm = this.fb.group({
      /*otp1: ["", [Validators.required]],
      otp2: ["", [Validators.required]],
      otp3: ["", [Validators.required]],
      otp4: ["", [Validators.required]]*/
    });

    this.emailForm = this.fb.group({
      user_id: [this.user_id],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //OTP methods start
  onOtpChange(otp) {
    this.inputOtp = otp;
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
    //console.log("INput OPT:" + this.ngOtpInput.length);
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.inputOtp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }
  //OTP methods end

  //form submit function
  onFormSubmit() {
    this.otpError = false;
    let otp = this.inputOtp;
    if (otp.length != 4) {
      this.otpError = true;
      return false;
    }

    this.myapp.spinner.show();
    const formData = new FormData();
    //let otp = this.otp1.value + this.otp2.value + this.otp3.value + this.otp4.value;
    formData.append("otp", otp);
    formData.append("user_verification_id", this.user_verification_id);
    formData.append("user_id", this.user_id);

    this.api.callAPI(formData, "verifyChangeEmailOtp1").subscribe(res => {
      if (res.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);

        //Remove local storage keys
        localStorage.removeItem("otp");
        localStorage.removeItem("user_verification_id");
        this.otpVerified = false;
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
      }
    });
  }

  //resent OTP function
  resendOtp() {
    this.otpError = false;
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.user_id);
    this.api.callAPI(formData, "requestChangeEmail1").subscribe(res => {
      if (res.status) {
        this.myapp.showSuccess(res.message);
        this.myapp.spinner.hide();
        this.otp = res.data.otp;
        this.user_verification_id = res.data.user_verification_id;
      } else {
        this.myapp.showError(res.message);
        this.myapp.spinner.hide();
      }
    });
    return false;
  }

  //change email form submit function
  onChangeEmailSubmit() {
    this.otpError = false;
    this.myapp.spinner.show();
    this.api
      .callAPI(this.emailForm.value, "requestChangeEmail2")
      .subscribe(res => {
        if (res.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccess(res.message);

          //Store the data in local storage
          window.localStorage.setItem(
            "user_verification_id",
            res.data.user_verification_id
          );
          window.localStorage.setItem("email", res.data.email);
          window.localStorage.setItem("otp", res.data.otp);

          this.router.navigate(["../new-email-verification"]);
        } else {
          this.myapp.spinner.hide();
          this.myapp.showError(res.message);
        }
      });
  }

  //Getor - To access the fields directly in the form in HTML
  /*get otp1() {
    return this.otpForm.get("otp1");
  }
  get otp2() {
    return this.otpForm.get("otp2");
  }
  get otp3() {
    return this.otpForm.get("otp3");
  }
  get otp4() {
    return this.otpForm.get("otp4");
  }*/
  get email() {
    return this.emailForm.get("email");
  }
}
