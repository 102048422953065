import { Component, OnInit } from "@angular/core";
import { MatAccordion } from "@angular/material/expansion";

@Component({
  selector: "app-faq",
  templateUrl: "./faq.component.html",
  styleUrls: ["./faq.component.css"]
})
export class FaqComponent implements OnInit {
  panelOpenState = true;
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
}
