import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-policy",
  templateUrl: "./policy.component.html",
  styleUrls: ["./policy.component.css"]
})
export class PolicyComponent implements OnInit {
  constructor(public myapp: AppComponent) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
}
