import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import { AuthService } from "angularx-social-login";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  //public userMenu = false;
  //public showDropDownMenu = false;
  public isShowDiv = true;
  public showMenu = true;

  constructor(
    public myapp: AppComponent,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    //localStorage.clear();
    /*if (window.localStorage.getItem("webUserLogin")) {
      this.myapp.userMenu = true;
    }*/

    //Load notification in 30 seconds interval
    setInterval(() => {
      if (window.localStorage.getItem("webUserLogin")) {
        this.getNotificaiton();
      }
    }, 30000);
  }

  showHideDropDown() {
    if (this.myapp.showDropDownMenu == true) {
      this.myapp.showDropDownMenu = false;
    } else {
      this.myapp.showDropDownMenu = true;
    }
    return false;
  }

  showHideDropDownMobile() {
    this.isShowDiv = !this.isShowDiv;
  }

  refresh(): void {
    window.location.href = "./index";
  }

  //Function to get notificaiton count, on 30 seconds interval
  getNotificaiton() {
    const formData = new FormData();
    formData.append("user_id", window.localStorage.getItem("webUserId"));
    formData.append(
      "customer_id",
      window.localStorage.getItem("webCustomerId")
    );
    formData.append("token", window.localStorage.getItem("token"));
    formData.append("count", "1");
    this.api.callAPI(formData, "getNotifications").subscribe(res => {
      if (res.status === true) {
        window.localStorage.setItem("userNotificationCount", res.count);
        this.myapp.userNotificationCount = res.count;
      } else if (res.status == "expired") {
        this.logout();
      }
    });
  }

  // Function to logout user
  logout() {
    //Destory the local storage
    localStorage.clear();
    this.signOut();
    this.myapp.userMenu = false;
    this.myapp.showDropDownMenu = false;
    this.myapp.showMenuToSMUser = true;
    this.myapp.cartCount = 0;
    this.router.navigate(["/"]);
    //window.location.href = "/";

    return false;
  }
  signOut(): void {
    this.authService.signOut();
  }

  showHideNavBar() {
    if (this.myapp.openNavbarOnMobile) this.myapp.openNavbarOnMobile = false;
    else this.myapp.openNavbarOnMobile = true;
  }
}
