import { Component, OnInit, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"]
})
export class ResetPasswordComponent implements OnInit {
  //Variable declration
  otpForm: FormGroup;
  showBtn = true;
  isPasswordMatch = false;

  otpError = false;
  inputOtp: any = "";
  showOtpComponent = true;
  @ViewChild("ngOtpInput", { static: false }) ngOtpInput: any;
  otpConfig = {
    allowNumbersOnly: true,
    length: 4,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "84px",
      height: "40px"
    }
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //If user tries to access without register
    if (!localStorage.getItem("resetUserId")) {
      this.router.navigate(["../forgot-password"]);
    }

    //Form Model Declaration with form validation for the fields
    this.otpForm = this.fb.group({
      /*otp1: ["", [Validators.required]],
      otp2: ["", [Validators.required]],
      otp3: ["", [Validators.required]],
      otp4: ["", [Validators.required]],*/
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$"
          )
        ]
      ],
      conf_pass: ["", [Validators.required]]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //OTP methods start
  onOtpChange(otp) {
    this.inputOtp = otp;
  }

  setVal(val) {
    this.ngOtpInput.setValue(val);
    //console.log("INput OPT:" + this.ngOtpInput.length);
  }

  onConfigChange() {
    this.showOtpComponent = false;
    this.inputOtp = null;
    setTimeout(() => {
      this.showOtpComponent = true;
    }, 0);
  }
  //OTP methods end

  //form submit function
  onFormSubmit() {
    this.otpError = false;
    let otp = this.inputOtp;
    if (otp.length != 4) {
      this.otpError = true;
      return false;
    }

    if (this.password.value != this.conf_pass.value) {
      this.isPasswordMatch = true;
      return false;
    }

    //this.showBtn = false;
    this.myapp.spinner.show();
    const formData = new FormData();
    //let otp = this.otp1.value + this.otp2.value + this.otp3.value + this.otp4.value;
    formData.append("otp", otp);
    formData.append("user_id", localStorage.getItem("resetUserId"));
    formData.append("new_pass", this.password.value);

    this.api.callAPI(formData, "resetPassword").subscribe(res => {
      if (res.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);
        //this.showBtn = true;
        localStorage.clear(); //Clear the variables of local storage
        this.router.navigate(["../login"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
        //this.showBtn = true;
      }
    });
  }

  //Getor - To access the fields directly in the form in HTML
  /*get otp1() {
    return this.otpForm.get("otp1");
  }
  get otp2() {
    return this.otpForm.get("otp2");
  }
  get otp3() {
    return this.otpForm.get("otp3");
  }
  get otp4() {
    return this.otpForm.get("otp4");
  }*/
  get password() {
    return this.otpForm.get("password");
  }
  get conf_pass() {
    return this.otpForm.get("conf_pass");
  }
}
