import { Component, OnInit } from "@angular/core";
import { OwlOptions } from "ngx-owl-carousel-o";
import { AppComponent } from "src/app/app.component";
declare var jQuery: any;
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  refresh(): void {
    window.location.reload();
  }

  imageObject = [
    {
      image: "assets/images/slider/1.jpg",
      thumbImage: "assets/images/slider/1.jpg",
      alt: "alt of image",
    },
    {
      image: "assets/images/slider/4.jpg",
      thumbImage: "assets/images/slider/4.jpg",
      alt: "alt of image",
    },
    {
      image: "assets/images/slider/3.jpg",
      thumbImage: "assets/images/slider/3.jpg",
      alt: "alt of image",
    },
  ];

  //Owl Slider options
  sliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["Previous", "Next"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: false,
  };

  //Why Choose Us Items
  whyChooseItems = [
    {
      id: 1,
      icon: "flaticon-clean",
      title: "Hygienic",
      desc:
        "At LaundryCUBE, we take responsiblitiy to keep New Zealand healthy. We regularly disinfect and clean our premises and transit vehicles. We keep orders separate dirty and laundry in store as well as in transit.",
    },
    {
      id: 2,
      icon: "flaticon-shipped",
      title: "Convenience",
      desc:
        "You can place an order at any time, any day and schedule a pick-up & delivery time that works best for you.  All you have to do is place an order and we'll get the job done quickly and efficiently saving you time to enjoy life!",
    },
    {
      id: 3,
      icon: "flaticon-quality",
      title: "High Quality",
      desc:
        "At LaundryCUBE, we practice high standards of quality. We always meet or exceed customer expectations whilst remaining economically competitive.",
    },
    {
      id: 4,
      icon: "flaticon-business",
      title: "Affordable Pricing",
      desc:
        "It dosen’t cost your arms and legs to get extraordinary services and convenience. We provide affordable laundry service at your door step.",
    },
    {
      id: 5,
      icon: "flaticon-shipped",
      title: "Convenience",
      desc:
        "We use best quality eco-friendly products to do your laundry. We avoid toxic chemicals which will drain to our seas and harm our natural habitats.",
    },
    /*{
      id: 4,
      icon: "flaticon-curriculum-vitae",
      title: "Professional",
      desc:
        "No blending of garments. Ultra-clean laundromats with extraordinary bundling to keep up freshness."
    },
    {
      id: 5,
      icon: "flaticon-washing-machine-for-laundry",
      title: "Best Equipments",
      desc:
        "No blending of garments. Ultra-clean laundromats with extraordinary bundling to keep up freshness."
    },*/
  ];

  constructor(public myapp: AppComponent) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    (function ($) {
      $(".counter-count").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 5000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    })(jQuery);
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
}
