import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"]
})
export class HelpComponent implements OnInit {
  //Variable declration
  contactForm: FormGroup;
  showBtn = true;
  customer_id: any = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.api.isUserLogin();
    this.customer_id = window.localStorage.getItem("webCustomerId");
    //Form Model Declaration with form validation for the fields
    this.contactForm = this.fb.group({
      customer_id: [this.customer_id],
      subject: [
        "",
        [
          //Validators.required,
          Validators.minLength(3),
          Validators.maxLength(100),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      message: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500)
        ]
      ]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
  //form submit function
  onFormSubmit() {
    //this.myapp.hideAlerts();
    //console.log("Service Image:" + this.service_image.value);
    this.showBtn = false;
    this.api
      .callAPI(this.contactForm.value, "submitContactEnquiry")
      .subscribe(res => {
        if (res.status) {
          this.myapp.showSuccess(res.message);
          this.showBtn = true;
          this.resetForm();

          //Patch the Item data in menuForm
          this.contactForm.patchValue({
            customer_id: this.customer_id
          });
        } else {
          this.myapp.showError(res.message);
          this.showBtn = true;
        }
      });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.contactForm.reset();
  }

  //Getor - To access the fields directly in the form in HTML
  get subject() {
    return this.contactForm.get("subject");
  }
  get message() {
    return this.contactForm.get("message");
  }
}
