import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions,
} from "ngx-stripe";

@Component({
  selector: "app-review-order",
  templateUrl: "./review-order.component.html",
  styleUrls: ["./review-order.component.css"],
})
export class ReviewOrderComponent implements OnInit {
  //stripe variables
  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: "en",
  };
  stripeForm: FormGroup;

  //other variables
  userAddress: any = [];
  tax_rate: any = 0;
  user_id: any = 0;
  customer_id: any = 0;
  pickupDate: any = localStorage.getItem("pickup_date");
  address_id: any = localStorage.getItem("address_id");
  address: any = localStorage.getItem("address");
  customer_note: any = localStorage.getItem("customer_note");
  cartItems: any = []; //JSON.parse(localStorage.getItem("cartItems"));
  cartTotalAmt: any = 0;
  totalTaxAmt: any = 0;
  grandTotalAmt: any = 0;
  orderItems: any = [];
  package_id: any = 0;
  packageAppliedItems: any = [];
  packageAppliedAmount: any = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    public api: ApiService,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.api.isUserLogin();

    //Redirect to order screen if cart is empty
    if (!localStorage.getItem("cartItems")) {
      this.router.navigate(["../order-start"]);
    }

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");

    //Take the items only from the cart items array
    this.cartItems = JSON.parse(localStorage.getItem("cartItems"));
    //console.log("cart items", this.cartItems);
    for (var i = 0; i < this.cartItems.length; i++) {
      for (var j = 0; j < this.cartItems[i].items.length; j++) {
        this.orderItems.push(this.cartItems[i].items[j]);
      }
    }
    //console.log("order items", this.orderItems);
    this.getPackageData();
    //this.calculateCartTotal();

    //Load stripe aftern 3 seconds
    setTimeout(() => {
      this.loadStripeForm();
    }, 3000);
  }

  loadStripeForm() {
    console.log("Load stripe");
    //Stripe
    this.stripeForm = this.fb.group({
      name: ["", [Validators.required]],
    });
    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#222222",
              color: "#222222",
              lineHeight: "40px",
              fontWeight: 400,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "14px",
              "::placeholder": {
                color: "#999",
              },
            },
          },
        });
        this.card.mount("#card-element");
      }
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //Function to check package is available or not with the order items
  getPackageData() {
    const formData = new FormData();
    formData.append("customer_id", this.customer_id);
    //formData.append("cart_items", JSON.stringify(this.cartItems));
    formData.append("order_items", JSON.stringify(this.orderItems));
    this.api.callAPI(formData, "checkPackageItems").subscribe((res) => {
      if (res.status === true) {
        //console.log("Data: ", res.data);
        this.tax_rate = res.data.tax_rate;
        this.package_id = 0; //res.data.package_id;
        this.packageAppliedItems = res.data.packageAppliedItems;
        this.packageAppliedAmount = parseFloat(res.data.packageAppliedAmount);
        this.grandTotalAmt = parseFloat(res.data.grandTotalAmt);
        this.cartTotalAmt = parseFloat(res.data.total_amount);
        this.totalTaxAmt = parseFloat(res.data.tax_amount);

        //this.calculateCartTotal();
        //this.calculateTaxAmount();
      }
    });
  }

  //Function to calculate cart total
  /*calculateCartTotal() {
    let totalAmt: any = 0;
    if (this.tax_rate > 0) {
      let taxAmt = (this.grandTotalAmt / 100) * this.tax_rate;
      this.totalTaxAmt = parseFloat(taxAmt.toFixed(2));
      this.cartTotalAmt = this.grandTotalAmt - this.totalTaxAmt;
      this.cartTotalAmt.toFixed(2);
    }

    let total = this.grandTotalAmt - this.packageAppliedAmount;
    this.grandTotalAmt = parseFloat(total.toFixed(2));
  }*/

  //Function to calculate tax and grand Total
  /*calculateTaxAmount() {
    if (this.tax_rate > 0) {
      let taxAmt = (this.grandTotalAmt / 100) * this.tax_rate;
      this.totalTaxAmt = parseFloat(taxAmt.toFixed(2));
      this.cartTotalAmt = this.grandTotalAmt - this.totalTaxAmt;
      this.cartTotalAmt.toFixed(2);
    }
  }*/

  //Function to check items in exist or not in the service in Cart
  checkServiceItemExist(i) {
    if (this.cartItems[i].items.length) return true;
    else return false;
    //return true;
  }

  //Funciton to check item exist with pacakge or not
  getPackageAppliedItem(itemId: any) {
    //console.log("packageAppliedItems", this.packageAppliedItems);
    //console.log("looking for:" + itemId);
    for (var i = 0; i < this.packageAppliedItems.length; i++) {
      if (this.packageAppliedItems[i].item_id == itemId) {
        //Add the package ID in orderItems JSON
        let package_id = this.packageAppliedItems[i].package_id;
        //console.log("order items", this.orderItems);
        for (var j = 0; j < this.orderItems.length; j++) {
          if (this.orderItems[j].itemId == itemId)
            this.orderItems[j]["package_id"] = package_id;
        }
        return true;
        break;
      }
    }
    //console.log("order items", this.orderItems);
  }

  //Stripe buy function
  buy() {
    this.myapp.spinner.show();
    const name = this.stripeForm.get("name").value;
    this.stripeService.createToken(this.card, { name }).subscribe((result) => {
      if (result.token) {
        // Use the token to create a charge or a customer
        // https://stripe.com/docs/charges
        //console.log(result.token);
        //console.log("ID" + result.token.id);
        this.placeOrder(result.token.id);
      } else if (result.error) {
        // Error creating the token
        //console.log(result.error.message);
        this.myapp.spinner.hide();
        this.myapp.showError(result.error.message);
      }
    });
  }

  //Function to place order in DB
  placeOrder(stripeToken) {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("customer_id", this.customer_id);
    formData.append("user_id", this.user_id);
    formData.append("address_id", this.address_id);
    formData.append("pick_up_date", this.pickupDate);
    formData.append("customer_note", this.customer_note);
    formData.append("package_id", this.package_id);
    formData.append("items_json", JSON.stringify(this.orderItems));
    formData.append("total_amount", this.cartTotalAmt);
    formData.append("tax_amount", this.totalTaxAmt);
    formData.append("grand_total", this.grandTotalAmt);
    formData.append("package_amount", this.packageAppliedAmount);
    formData.append("stripeToken", stripeToken);

    this.api.callAPI(formData, "createOrder").subscribe((res) => {
      if (res.status === true) {
        this.myapp.spinner.hide();

        //clear items from session
        localStorage.removeItem("address");
        localStorage.removeItem("address_id");
        localStorage.removeItem("pickup_date");
        localStorage.removeItem("cartItems");
        localStorage.removeItem("customer_note");
        localStorage.removeItem("cartCount");
        this.myapp.cartCount = 0;

        window.localStorage.setItem("last_order_no", res.order_number);
        this.router.navigate(["../thankyou"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
      }
    });
  }
}
