import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"]
})
export class ForgotPasswordComponent implements OnInit {
  //Variable declration
  loginForm: FormGroup;
  showBtn = true;
  emailError = false;
  phoneError = false;
  emailOrPhone: number = 1;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    /*window.scrollTo(0, 0);*/
    //Form Model Declaration with form validation for the fields
    this.loginForm = this.fb.group({
      recover_with: ["1", [Validators.required]],
      country_code: ["+64"],
      mobile_number: [
        "",
        [
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      email: [
        "",
        [Validators.email, Validators.minLength(3), Validators.maxLength(100)]
      ]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //form submit function
  onFormSubmit() {
    let country_code = this.country_code.value;

    let username = "";
    if (this.recover_with.value == "1") {
      username = this.email.value;
      if (username == "") {
        this.emailError = true;
        return false;
      } else {
        country_code = 0;
      }
    }

    if (this.recover_with.value == "2") {
      username = this.mobile_number.value;
      if (username == "") {
        this.phoneError = true;
        return false;
      }
    }

    this.showBtn = false;
    const formData = new FormData();

    formData.append("username", username);
    formData.append("country_code", country_code);
    this.api.callAPI(formData, "forgotPassword").subscribe(res => {
      if (res.status) {
        this.myapp.showSuccess(res.message);
        this.showBtn = true;
        console.log("data", res.data);
        //Store the OTP in local storage
        window.localStorage.setItem("resetOtp", res.data.otp);
        window.localStorage.setItem("resetUserId", res.data.user_id);

        this.router.navigate(["../reset-password"]);
      } else {
        this.myapp.showError(res.message);
        this.showBtn = true;
      }
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get recover_with() {
    return this.loginForm.get("recover_with");
  }
  get country_code() {
    return this.loginForm.get("country_code");
  }
  get mobile_number() {
    return this.loginForm.get("mobile_number");
  }
  get email() {
    return this.loginForm.get("email");
  }

  setRecoveryType(type: number) {
    this.emailOrPhone = type;
    this.emailError = false;
    this.phoneError = false;
  }
}
