import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-become-partner",
  templateUrl: "./become-partner.component.html",
  styleUrls: ["./become-partner.component.css"]
})
export class BecomePartnerComponent implements OnInit {
  //Variable declration
  laundryForm: FormGroup;
  driverForm: FormGroup;
  showForm = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //this.myapp.spinner.show();
    //Form Model Declaration with form validation for the fields

    this.laundryForm = this.fb.group({
      business_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(200),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      phone: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      email: [
        "",
        [
          Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ],
      address: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500)
        ]
      ]
    });

    this.driverForm = this.fb.group({
      driver_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      driver_phone: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(15),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      driver_email: [
        "",
        [
          //Validators.required,
          Validators.email,
          Validators.minLength(3),
          Validators.maxLength(100)
        ]
      ],
      driver_address: [
        "",
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500)
        ]
      ],
      driving_license_no: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(20),
          Validators.pattern("^[A-Za-z0-9]+$")
        ]
      ]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  showHideForm(type) {
    if (type == "1") this.showForm = true;
    else this.showForm = false;
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    this.api
      .callAPI(this.laundryForm.value, "submitPartnerEnquiry")
      .subscribe(res => {
        if (res.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccess(res.message);
          this.resetForm();
        } else {
          this.myapp.spinner.hide();
          this.myapp.showError(res.message);
        }
      });
  }

  //driver form submit function
  onDriverFormSubmit() {
    this.myapp.spinner.show();
    this.api
      .callAPI(this.driverForm.value, "submitPartnerEnquiry")
      .subscribe(res => {
        if (res.status) {
          this.myapp.spinner.hide();
          this.myapp.showSuccess(res.message);
          this.resetForm();
        } else {
          this.myapp.spinner.hide();
          this.myapp.showError(res.message);
        }
      });
  }

  //Reset the Form
  resetForm() {
    this.laundryForm.reset();
    this.driverForm.reset();
  }

  //Getor - To access the fields directly in the form in HTML
  get business_name() {
    return this.laundryForm.get("business_name");
  }
  get name() {
    return this.laundryForm.get("name");
  }
  get email() {
    return this.laundryForm.get("email");
  }
  get phone() {
    return this.laundryForm.get("phone");
  }
  get address() {
    return this.laundryForm.get("address");
  }

  get driver_name() {
    return this.driverForm.get("driver_name");
  }
  get driver_email() {
    return this.driverForm.get("driver_email");
  }
  get driver_phone() {
    return this.driverForm.get("driver_phone");
  }
  get driver_address() {
    return this.driverForm.get("driver_address");
  }
  get driving_license_no() {
    return this.driverForm.get("driving_license_no");
  }
}
