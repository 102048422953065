import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.css"]
})
export class TermsComponent implements OnInit {
  constructor(public myapp: AppComponent) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
}
