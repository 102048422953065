import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-my-profile",
  templateUrl: "./my-profile.component.html",
  styleUrls: ["./my-profile.component.css"]
})
export class MyProfileComponent implements OnInit {
  isUserLogin = false;
  profile: any = [];
  user_id: any = 0;
  customer_id: any = 0;
  showLoader = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.isUserLogin = this.api.isUserLogin();

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");
    this.getUserProfile(this.user_id, this.customer_id);
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  getUserProfile(user_id, customer_id) {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("customer_id", customer_id);
    this.api.callAPI(formData, "getMyProfile").subscribe(res => {
      if (res.status === true) {
        //console.log("Data: ", res.data);
        this.profile = res.data;

        //If name & DOB is not filled then shopping not allowd
        /*if (this.profile.first_name == "" || this.profile.date_of_birth == "") {
          window.localStorage.setItem("canBuyItem", "0");
        } else {
          window.localStorage.setItem("canBuyItem", "1");
        }*/
      }
    });
  }

  verifyPhoneEmail(type) {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.user_id);
    formData.append("verification_type", type);
    this.api.callAPI(formData, "sendOtpPhoneEamil").subscribe(res => {
      if (res.status === true) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);

        //Store the data in local storage
        window.localStorage.setItem(
          "user_verification_id",
          res.data.user_verification_id
        );
        window.localStorage.setItem(
          "verification_type",
          res.data.verification_type
        );
        window.localStorage.setItem("otp", res.data.otp);

        this.router.navigate(["../email-phone-verification"]);
      } else {
        this.myapp.spinner.hide();
      }
    });
  }

  changePhone() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.user_id);
    this.api.callAPI(formData, "changePhone1").subscribe(res => {
      if (res.status === true) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);

        //Store the data in local storage
        window.localStorage.setItem(
          "user_verification_id",
          res.data.user_verification_id
        );
        window.localStorage.setItem(
          "verification_type",
          res.data.verification_type
        );
        window.localStorage.setItem("otp", res.data.otp);

        this.router.navigate(["../current-phone-verification"]);
      } else {
        this.myapp.spinner.hide();
      }
    });
  }

  changeEmail() {
    this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("user_id", this.user_id);
    this.api.callAPI(formData, "requestChangeEmail1").subscribe(res => {
      if (res.status === true) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);

        //Store the data in local storage
        window.localStorage.setItem(
          "user_verification_id",
          res.data.user_verification_id
        );
        window.localStorage.setItem(
          "verification_type",
          res.data.verification_type
        );
        window.localStorage.setItem("otp", res.data.otp);

        this.router.navigate(["../current-email-verification"]);
      } else {
        this.myapp.spinner.hide();
      }
    });
  }
}
