import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ServicesComponent } from "./services/services.component";
import { PackagesComponent } from "./packages/packages.component";
import { PricingComponent } from "./pricing/pricing.component";
import { ContactComponent } from "./contact/contact.component";
import { FaqComponent } from "./faq/faq.component";
import { HelpComponent } from "./help/help.component";
import { PolicyComponent } from "./policy/policy.component";
import { TermsComponent } from "./terms/terms.component";
import { LoginComponent } from "./user/login/login.component";
import { SignupComponent } from "./user/signup/signup.component";
import { ForgotPasswordComponent } from "./user/forgot-password/forgot-password.component";
import { OrderComponent } from "./order/order/order.component";
import { OtpVerificationComponent } from "./user/otp-verification/otp-verification.component";
import { MyProfileComponent } from "./user/my-profile/my-profile.component";
import { ResetPasswordComponent } from "./user/reset-password/reset-password.component";
import { EditProfileComponent } from "./user/edit-profile/edit-profile.component";
import { EmailPhoneVerificationComponent } from "./user/email-phone-verification/email-phone-verification.component";
import { ChangePasswordComponent } from "./user/change-password/change-password.component";
import { CurrentPhoneVerificationComponent } from "./user/change-phone/current-phone-verification/current-phone-verification.component";
import { NewPhoneVerificationComponent } from "./user/change-phone/new-phone-verification/new-phone-verification.component";
import { CurrentEmailVerificationComponent } from "./user/change-email/current-email-verification/current-email-verification.component";
import { NewEmailVerificationComponent } from "./user/change-email/new-email-verification/new-email-verification.component";
import { ManageAddressComponent } from "./user/manage-address/manage-address.component";
import { MyOrdersComponent } from "./user/my-orders/my-orders.component";
import { OrderDetailComponent } from "./user/order-detail/order-detail.component";
import { NotificationsComponent } from "./user/notifications/notifications.component";
import { BuyPackageComponent } from "./buy-package/buy-package.component";
import { MyPackagesComponent } from "./user/my-packages/my-packages.component";
import { PickupComponent } from "./order/pickup/pickup.component";
import { ReviewOrderComponent } from "./order/review-order/review-order.component";
import { ThankyouComponent } from "./order/thankyou/thankyou.component";
import { BecomePartnerComponent } from "./become-partner/become-partner.component";

const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
    data: { title: "Welcome to LaundryCUBE" },
  },
  {
    path: "about",
    component: AboutComponent,
    data: { title: "About Us | LaundryCUBE" },
  },
  {
    path: "services",
    component: ServicesComponent,
    data: { title: "Services | LaundryCUBE" },
  },
  {
    path: "packages",
    component: PackagesComponent,
    data: { title: "Packages | LaundryCUBE" },
  },
  {
    path: "buy-package/:id",
    component: BuyPackageComponent,
    data: { title: "Buy Package | LaundryCUBE" },
  },
  {
    path: "pricing",
    component: PricingComponent,
    data: { title: "Pricing | LaundryCUBE" },
  },
  {
    path: "contact",
    component: ContactComponent,
    data: { title: "Contact Us | LaundryCUBE" },
  },
  {
    path: "faq",
    component: FaqComponent,
    data: { title: "FAQs | LaundryCUBE" },
  },
  {
    path: "help-support",
    component: HelpComponent,
    data: { title: "Help & Support | LaundryCUBE" },
  },
  {
    path: "privacy-policy",
    component: PolicyComponent,
    data: { title: "Privacy Policy | LaundryCUBE" },
  },
  {
    path: "terms-conditions",
    component: TermsComponent,
    data: { title: "Terms & Conditions | LaundryCUBE" },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { title: "Login | LaundryCUBE" },
  },
  {
    path: "signup",
    component: SignupComponent,
    data: { title: "Signup | LaundryCUBE" },
  },
  {
    path: "otp-verification",
    component: OtpVerificationComponent,
    data: { title: "OTP Verification | LaundryCUBE" },
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent,
    data: { title: "Forgot Password | LaundryCUBE" },
  },
  {
    path: "reset-password",
    component: ResetPasswordComponent,
    data: { title: "Reset Password | LaundryCUBE" },
  },
  {
    path: "order-start",
    component: OrderComponent,
    data: { title: "Order | LaundryCUBE" },
  },
  {
    path: "my-profile",
    component: MyProfileComponent,
    data: { title: "My Profile | LaundryCUBE" },
  },
  {
    path: "edit-profile",
    component: EditProfileComponent,
    data: { title: "Edit Profile | LaundryCUBE" },
  },
  {
    path: "email-phone-verification",
    component: EmailPhoneVerificationComponent,
    data: { title: "Email Phone Verification | LaundryCUBE" },
  },
  {
    path: "change-password",
    component: ChangePasswordComponent,
    data: { title: "Change Password | LaundryCUBE" },
  },
  {
    path: "current-phone-verification",
    component: CurrentPhoneVerificationComponent,
    data: { title: "Current Phone Verification | LaundryCUBE" },
  },
  {
    path: "new-phone-verification",
    component: NewPhoneVerificationComponent,
    data: { title: "New Phone Verification | LaundryCUBE" },
  },
  {
    path: "current-email-verification",
    component: CurrentEmailVerificationComponent,
    data: { title: "Current Email Verification | LaundryCUBE" },
  },
  {
    path: "new-email-verification",
    component: NewEmailVerificationComponent,
    data: { title: "New Email Verification | LaundryCUBE" },
  },
  {
    path: "manage-address",
    component: ManageAddressComponent,
    data: { title: "Manage Address | LaundryCUBE" },
  },
  {
    path: "manage-address/:str",
    component: ManageAddressComponent,
    data: { title: "Manage Address | LaundryCUBE" },
  },
  {
    path: "my-orders",
    component: MyOrdersComponent,
    data: { title: "My Orders | LaundryCUBE" },
  },
  {
    path: "order-detail/:order_number",
    component: OrderDetailComponent,
    data: { title: "Order Detail | LaundryCUBE" },
  },
  {
    path: "order-detail/:order_number/:noti_id",
    component: OrderDetailComponent,
    data: { title: "Order Detail | LaundryCUBE" },
  },
  {
    path: "notifications",
    component: NotificationsComponent,
    data: { title: "Notifications | LaundryCUBE" },
  },
  {
    path: "my-packages",
    component: MyPackagesComponent,
    data: { title: "My Packages | LaundryCUBE" },
  },
  {
    path: "pickup",
    component: PickupComponent,
    data: { title: "Order Pickup | LaundryCUBE" },
  },
  {
    path: "review-order",
    component: ReviewOrderComponent,
    data: { title: "Review Order | LaundryCUBE" },
  },
  {
    path: "thankyou",
    component: ThankyouComponent,
    data: { title: "Thank You | LaundryCUBE" },
  },
  {
    path: "become-a-partner",
    component: BecomePartnerComponent,
    data: { title: "Become a Partner | LaundryCUBE" },
  },

  //404 or Redirect to Home
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
//export const routingComponents = [OrderDetailComponent];
