import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { OwlOptions } from "ngx-owl-carousel-o";

@Component({
  selector: "app-about",
  templateUrl: "./about.component.html",
  styleUrls: ["./about.component.css"]
})
export class AboutComponent implements OnInit {
  //Owl Slider options
  sliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["Previous", "Next"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  //Why Choose Us Items
  whyChooseItems = [
    {
      id: 1,
      icon: "flaticon-clean",
      title: "Hygienic",
      desc:
        "At LaundryCUBE, we take responsiblitiy to keep New Zealand healthy. We regularly disinfect and clean our premises and transit vehicles. We keep orders separate dirty and laundry in store as well as in transit."
    },
    {
      id: 2,
      icon: "flaticon-shipped",
      title: "Convenience",
      desc:
        "You can place an order at any time, any day and schedule a pick-up & delivery time that works best for you.  All you have to do is place an order and we'll get the job done quickly and efficiently saving you time to enjoy life!"
    },
    {
      id: 3,
      icon: "flaticon-quality",
      title: "High Quality",
      desc:
        "At LaundryCUBE, we practice high standards of quality. We always meet or exceed customer expectations whilst remaining economically competitive."
    },
    {
      id: 4,
      icon: "flaticon-business",
      title: "Affordable Pricing",
      desc:
        "It dosen’t cost your arms and legs to get extraordinary services and convenience. We provide affordable laundry service at your door step."
    }
    /*{
      id: 5,
      icon: "flaticon-shipped",
      title: "Convenience",
      desc:
        "We use best quality eco-friendly products to do your laundry. We avoid toxic chemicals which will drain to our seas and harm our natural habitats.",
    },*/
    /*{
      id: 4,
      icon: "flaticon-curriculum-vitae",
      title: "Professional",
      desc:
        "No blending of garments. Ultra-clean laundromats with extraordinary bundling to keep up freshness."
    },
    {
      id: 5,
      icon: "flaticon-washing-machine-for-laundry",
      title: "Best Equipments",
      desc:
        "No blending of garments. Ultra-clean laundromats with extraordinary bundling to keep up freshness."
    },*/
  ];

  //Owl Slider options
  teamSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: [
      '<span class="fa fa-angle-left"></span>',
      '<span class="fa fa-angle-right"></span>'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  };
  //Why Choose Us Items
  teamMembers = [
    {
      id: 1,
      image: "1.png",
      name: "Dharmesh",
      designation: "CEO",
      fb: "#",
      lin: "#",
      tweet: "#"
    },
    {
      id: 2,
      image: "2.png",
      name: "Bhaskar",
      designation: "Tech Expert",
      fb: "#",
      lin: "#",
      tweet: "#"
    },
    {
      id: 3,
      image: "3.png",
      name: "Srinivas",
      designation: "Operation Manager",
      fb: "#",
      lin: "#",
      tweet: "#"
    },
    {
      id: 4,
      image: "4.png",
      name: "Michael Jhon",
      designation: "Service Manager",
      fb: "#",
      lin: "#",
      tweet: "#"
    }
  ];

  //Owl Slider options
  historySliderOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<span class="fa fa-long-arrow-left"></span>',
      '<span class="fa fa-long-arrow-right"></span>'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: false
  };
  //history Items
  historyItems = [
    {
      id: 1,
      year: "1952",
      heading: "Laundry & Dry Cleaning Foundation",
      desc:
        "We have built the company from humble roots, and are known for impeccable services. Having worked with customers who’ve trusted us for an amazingly long time, we have always kept up our quality of results as well as relationships."
    }
    /*{
      id: 2,
      year: "1952",
      heading: "Laundry & Dry Cleaning Foundation",
      desc:
        "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus"
    },
    {
      id: 3,
      year: "1952",
      heading: "Laundry & Dry Cleaning Foundation",
      desc:
        "Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus"
    }*/
  ];

  //Owl Slider options
  /*partnerSliderOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["Previous", "Next"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    },
    nav: false
  };

  //Partners Items
  partners = [
    {
      id: 1,
      image: "brand.png"
    },
    {
      id: 2,
      image: "brand.png"
    },
    {
      id: 3,
      image: "brand.png"
    },
    {
      id: 4,
      image: "brand.png"
    },
    {
      id: 5,
      image: "brand.png"
    },
    {
      id: 6,
      image: "brand.png"
    },
    {
      id: 7,
      image: "brand.png"
    },
    {
      id: 8,
      image: "brand.png"
    }
  ];*/

  constructor(public myapp: AppComponent) {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/
}
