import { Component, OnInit } from "@angular/core";
import { ApiService } from "../service/api.service";
import { Router, ParamMap, ActivatedRoute } from "@angular/router";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  ElementsOptions,
} from "ngx-stripe";

@Component({
  selector: "app-buy-package",
  templateUrl: "./buy-package.component.html",
  styleUrls: ["./buy-package.component.css"],
})
export class BuyPackageComponent implements OnInit {
  //stripe variables
  elements: Elements;
  card: StripeElement;
  elementsOptions: ElementsOptions = {
    locale: "en",
  };
  stripeForm: FormGroup;

  //other variables
  package: any = [];
  package_id: any = 0;
  customer_id: any = 0;
  user_id: any = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    private stripeService: StripeService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.api.isUserLogin();
    this.customer_id = localStorage.getItem("webCustomerId");
    this.user_id = localStorage.getItem("webUserId");

    //Profile Completion validation
    /*if (localStorage.getItem("canBuyItem") == "0") {
      this.myapp.showWarning("Please complete your profile to buy a package");
      this.router.navigate(["../edit-profile"]);
    }*/

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.package_id = params.get("id");
      if (this.package_id) {
        this.getPacakgeDetail(this.package_id);
      }
    });

    //Stripe
    this.stripeForm = this.fb.group({
      name: ["", [Validators.required]],
    });
    this.stripeService.elements(this.elementsOptions).subscribe((elements) => {
      this.elements = elements;
      // Only mount the element the first time
      if (!this.card) {
        this.card = this.elements.create("card", {
          style: {
            base: {
              iconColor: "#222222",
              color: "#222222",
              lineHeight: "40px",
              fontWeight: 400,
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
              fontSize: "14px",
              "::placeholder": {
                color: "#999",
              },
            },
          },
        });
        this.card.mount("#card-element");
      }
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //Function to get package detail
  getPacakgeDetail(package_id) {
    const formData = new FormData();
    formData.append("package_id", package_id);
    formData.append("user_id", this.user_id);
    this.api.callAPI(formData, "getPacakgeDetail").subscribe((res) => {
      if (res.status === true) {
        //console.log("Package: ", res.data);
        this.package = res.data;
      } else {
        this.myapp.showWarning(res.message);
        this.router.navigate(["../my-profile"]);
      }
    });
  }

  //Stripe buy function
  buy() {
    this.myapp.spinner.show();
    const name = this.stripeForm.get("name").value;
    this.stripeService.createToken(this.card, { name }).subscribe((result) => {
      if (result.token) {
        // Use the token to create a charge or a customer
        // https://stripe.com/docs/charges
        //console.log(result.token);
        //console.log("ID" + result.token.id);
        this.proceedToPayment(result.token.id);
      } else if (result.error) {
        // Error creating the token
        //console.log(result.error.message);
        this.myapp.spinner.hide();
        this.myapp.showError(result.error.message);
      }
    });
  }

  //Proceed to payment function
  proceedToPayment(stripeToken) {
    //this.myapp.spinner.show();
    const formData = new FormData();
    formData.append("package_id", this.package_id);
    formData.append("customer_id", this.customer_id);
    formData.append("stripeToken", stripeToken);
    this.api.callAPI(formData, "buyPackage").subscribe((res) => {
      if (res.status === true) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);
        this.router.navigate(["../my-packages"]);
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
      }
    });
  }
}
