import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-pickup",
  templateUrl: "./pickup.component.html",
  styleUrls: ["./pickup.component.css"],
})
export class PickupComponent implements OnInit {
  userAddress: any = [];
  addressExist = false;
  user_id: any = 0;
  customer_id: any = 0;
  pickupDate: any = "";
  address: any = "";
  address_id: any = "";
  errorMsg: any = "";
  customer_note: any = "";
  todayDate = this.api.todayDate();
  pickUpMaxDate = this.api.getDaysAfterDate(7);

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.api.isUserLogin();

    //Redirect to order screen if cart is empty
    if (!localStorage.getItem("cartItems")) {
      this.router.navigate(["../order-start"]);
    }

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");
    this.getUserAddress(this.user_id, this.customer_id);

    //console.log("cart items", JSON.parse(localStorage.getItem("cartItems")));
    if (localStorage.getItem("pickup_date")) {
      //console.log("Pickup date is set");
      this.pickupDate = localStorage.getItem("pickup_date");
      this.address_id = localStorage.getItem("address_id");
      this.address = localStorage.getItem("address");
      this.customer_note = localStorage.getItem("customer_note");
    }
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //Get user address from DB
  getUserAddress(user_id, customer_id) {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("customer_id", customer_id);
    this.api.callAPI(formData, "getAllAddresses").subscribe((res) => {
      if (res.status === true) {
        //console.log("Data: ", res.data)
        this.addressExist = true;
        this.userAddress = res.data;

        //Set the last order id to address default selected
        for (let i = 0; i < this.userAddress.length; i++) {
          if (this.userAddress[i].used_in_last_order == 1) {
            this.address_id = this.userAddress[i].address_id;
            this.address =
              this.userAddress[i].complete_address +
              " " +
              this.userAddress[i].landmark;
          }
        }
      } else {
        this.addressExist = false;
      }
    });
  }

  //Select delivery address
  selectAddress(index) {
    //Store the address ID in session
    //window.localStorage.setItem("address_id", address_id);
    this.address_id = this.userAddress[index].address_id;
    this.address =
      this.userAddress[index].complete_address +
      " " +
      this.userAddress[index].landmark;
  }

  //Select pickup date
  /*selectPickupDate(event) {
    //Store the date in session
    console.log(event.target.value);
    window.localStorage.setItem("pickup_date", "1");
  }*/

  //Fucntion to redirect user to review route
  proceedToReview() {
    this.errorMsg = "";
    if (this.address_id != "" && this.pickupDate != "") {
      let pDate = this.api.stringToDateFun(this.pickupDate);

      if (pDate < this.todayDate) {
        this.errorMsg = "Please select valid pickup date";
        return false;
      } else {
        //store required param in session
        window.localStorage.setItem("pickup_date", this.pickupDate);
        window.localStorage.setItem("address_id", this.address_id);
        window.localStorage.setItem("address", this.address);
        window.localStorage.setItem("customer_note", this.customer_note);
        this.router.navigate(["../review-order"]);
      }
    } else {
      this.errorMsg = "Please select pickup date and address";
    }
  }
}
