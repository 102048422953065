import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-order",
  templateUrl: "./order.component.html",
  styleUrls: ["./order.component.css"]
})
export class OrderComponent implements OnInit {
  isUserLogin = false;
  services: any = [];
  user_id: any = 0;
  customer_id: any = 0;
  active_tab: any = 0;
  cartItems: any = new Array();
  cartTotalAmt: number = 0;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.isUserLogin = this.api.isUserLogin();
    //console.log("can buy:" + localStorage.getItem("canBuyItem"));

    //Profile Completion validation
    /*if (localStorage.getItem("canBuyItem") == "0") {
      this.myapp.showWarning("Please complete your profile to place an order");
      this.router.navigate(["../edit-profile"]);
    }*/

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");
    this.loadServices();
    //this.cartItems[0] = [];

    /*this.cartItems[0] = [];
    this.cartItems[1] = {
      serviceName: "Wash & Fold",
      items: [
        {
          itemId: "4",
          itemName: "Small Box",
          unitType: "box",
          qty: "1",
          price: "8.00"
        },
        {
          itemId: "4",
          itemName: "Medium Box",
          unitType: "box",
          qty: "1",
          price: "8.00"
        }
      ]
    };

    this.cartItems[2] = {
      serviceName: "Laundry",
      items: [
        {
          itemId: "4",
          itemName: "Small Box",
          unitType: "box",
          qty: "1",
          price: "8.00"
        },
        {
          itemId: "4",
          itemName: "Medium Box",
          unitType: "box",
          qty: "1",
          price: "8.00"
        }
      ]
    };
    console.log("cart items", this.cartItems);*/
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //Load the services on page load
  loadServices() {
    const formData = new FormData();
    formData.append("user_id", this.user_id);
    this.api.callAPI(formData, "getAllServices").subscribe(res => {
      if (res.status === true) {
        //console.log("Services: ", res.data);
        this.services = res.data;

        if (!localStorage.getItem("cartItems")) {
          //console.log("add again");
          for (let i = 0; i < this.services.length; i++) {
            let obj = {
              serviceName: this.services[i].service_name,
              items: []
            };
            this.cartItems.push(obj);
          }
        }
        //console.log("cart services", this.cartItems);
      } else {
        this.myapp.showWarning(res.message);
        this.router.navigate(["../my-profile"]);
      }
    });

    //Assign session cart data into variable
    if (localStorage.getItem("cartItems")) {
      this.cartItems = [];
      let items = JSON.parse(localStorage.getItem("cartItems"));
      this.cartItems = items;
      this.calculateCartTotal();
      //console.log(this.cartItems);
    }
  }

  //Show/Hide service items tab
  showHideTab(index) {
    this.active_tab = index;
    return false;
  }

  //Function to Increase qty of Item in Cart
  increaseQty(si: any, ii: any) {
    let serviceName = this.services[si].service_name;
    let serviceId = this.services[si].service_id;
    let itemId = this.services[si].items[ii].item_id;
    let itemName = this.services[si].items[ii].item_name;
    let price = this.services[si].items[ii].price;

    let cartData = { itemId: itemId, itemName: itemName, price: price, qty: 1 };
    //console.log("items to add", cartData);

    let index = -1;
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      for (var j = 0; j < this.cartItems[i].items.length; j++) {
        if (this.cartItems[i]["items"][j].itemId === itemId) {
          index = j;
          break;
        }
      }
    }
    //console.log("found index: " + index);
    if (index < 0) {
      //Insert the Items in Cart
      this.cartItems[si]["items"].push(cartData); //Push data in cart
    } else {
      //Update the Items in Cart
      this.cartItems[si]["items"][index].qty++;
      this.cartItems[si]["items"][index].price =
        price * this.cartItems[si]["items"][index].qty;
    }
    this.calculateCartTotal();
    //this.checkServiceItemExist(index);
    //console.log("Cart items", this.cartItems);
    //console.log("total items: " + this.cartItems.length);
  }

  //Function to Decrease qty of Item in Cart
  decreaseQty(si: any, ii: any) {
    //let serviceName = this.services[si].service_name;
    //let serviceId = this.services[si].service_id;
    let itemId = this.services[si].items[ii].item_id;
    //let itemName = this.services[si].items[ii].item_name;
    let price = this.services[si].items[ii].price;

    let index = -1;
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      for (var j = 0; j < this.cartItems[i].items.length; j++) {
        if (this.cartItems[i]["items"][j].itemId === itemId) {
          index = j;
          break;
        }
      }
    }

    if (index >= 0) {
      //Update the Items in Cart
      this.cartItems[si]["items"][index].qty--;
      this.cartItems[si]["items"][index].price =
        price * this.cartItems[si]["items"][index].qty;

      //If qty reaches to 0, then remove the item from cart
      if (this.cartItems[si]["items"][index].qty == 0)
        this.cartItems[si]["items"].splice(index, 1);
    }
    this.calculateCartTotal();
    //this.checkServiceItemExist(index);
    //console.log("Cart items", this.cartItems);
    //console.log("total items: " + this.cartItems.length);
  }

  //Function to display value in qty textbox
  getQuantity(si: any, ii: any, item_id: any) {
    let qty = "0";
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      for (var j = 0; j < this.cartItems[i].items.length; j++) {
        if (this.cartItems[i]["items"][j].itemId === item_id) {
          qty = this.cartItems[si]["items"][j].qty;
          break;
        }
      }
    }

    return qty;
  }

  //Function to check items in exist or not in the service in Cart
  checkServiceItemExist(i) {
    //console.log("item index: " + this.cartItems[i].items.length);
    //First search itemId is exit or not in the CART
    if (this.cartItems[i].items.length) return true;
    else return false;
  }

  //Function to calculate cart total
  calculateCartTotal() {
    let totalAmt: any = 0;
    let totalCartItems: any = 0;
    //First search itemId is exit or not in the CART
    for (var i = 0; i < this.cartItems.length; i++) {
      for (var j = 0; j < this.cartItems[i].items.length; j++) {
        let total = parseFloat(this.cartItems[i]["items"][j].price);
        totalAmt = totalAmt + total;

        totalCartItems = totalCartItems + this.cartItems[i]["items"][j].qty;
      }
    }
    this.cartTotalAmt = totalAmt; //toFixed(2);
    //console.log("cart total: " + this.cartTotalAmt);

    //store the cart items in session
    window.localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
    window.localStorage.setItem("cartCount", totalCartItems);
    this.myapp.cartCount = totalCartItems;

    //console.log("total cart qty: " + totalCartItems);
  }

  //Function to add cart items in session
  addCartToSession() {
    //window.localStorage.setItem("cartItems", JSON.stringify(this.cartItems));
    //console.log("cart items", JSON.parse(localStorage.getItem("cartItems")));
    this.router.navigate(["../pickup"]);
  }
}
