import { Component, OnInit } from "@angular/core";
import { ApiService } from "../service/api.service";

@Component({
  selector: "app-packages",
  templateUrl: "./packages.component.html",
  styleUrls: ["./packages.component.css"]
})
export class PackagesComponent implements OnInit {
  packages: any = [];
  constructor(private api: ApiService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loadPackages();
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  loadPackages() {
    this.api.callAPI(null, "getPackages").subscribe(res => {
      if (res.status === true) {
        console.log("Packages: ", res.data);
        this.packages = res.data;
      }
    });
  }
}
