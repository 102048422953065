import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { MapsAPILoader, MouseEvent } from "@agm/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { Router, ActivatedRoute, ParamMap } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-manage-address",
  templateUrl: "./manage-address.component.html",
  styleUrls: ["./manage-address.component.css"],
})
export class ManageAddressComponent implements OnInit {
  addForm: FormGroup;
  addressHeading: string = "Add New Address";
  isEditing = false;
  userAddress: any = [];
  addressExist = false;
  user_id: any = 0;
  customer_id: any = 0;
  paramActive = false;

  //Map variables
  isMapShow: boolean = false;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  @ViewChild("search", { static: false })
  public searchElementRef: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.api.isUserLogin();

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");
    this.getUserAddress(this.user_id, this.customer_id);

    //Form Model Declaration with form validation for the fields
    this.addForm = this.fb.group({
      address_id: [""],
      customer_id: [this.customer_id],
      location: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500),
        ],
      ],
      complete_address: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(500),
        ],
      ],
      landmark: ["", [Validators.minLength(3), Validators.maxLength(200)]],
      lat: [""],
      long: [""],
      address_type: ["", [Validators.required]],
    });

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      let autocomplete = new google.maps.places.Autocomplete(
        //this.searchElementRef.nativeElement
        this.searchElementRef.nativeElement,
        {
          types: ["address"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          this.isMapShow = true;
          console.log("Map is showing");
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 14;
          this.address = place.formatted_address;

          this.addForm.controls["lat"].patchValue(this.latitude);
          this.addForm.controls["long"].patchValue(this.longitude);
          this.addForm.controls["complete_address"].patchValue(this.address);
          this.addForm.controls["location"].patchValue(this.address);
        });
      });
    });

    //Access the Paramter from URL
    this.route.paramMap.subscribe((params: ParamMap) => {
      let param = params.get("str");
      if (param) {
        this.paramActive = true;
      }
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        //this.latitude = position.coords.latitude;
        //this.longitude = position.coords.longitude;
        this.zoom = 14;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: parseFloat(latitude), lng: parseFloat(longitude) } },
      (results, status) => {
        console.log(results);
        console.log(status);
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 14;
            this.address = results[0].formatted_address;

            this.addForm.controls["lat"].patchValue(this.latitude);
            this.addForm.controls["long"].patchValue(this.longitude);
            this.addForm.controls["complete_address"].patchValue(this.address);
            this.addForm.controls["location"].patchValue(this.address);
          } else {
            //window.alert("No results found");
            console.log("No results found");
          }
        } else {
          //window.alert("Geocoder failed due to: " + status);
          console.log("Geocoder failed due to: " + status);
        }
      }
    );
  }

  //Get user address from DB
  getUserAddress(user_id, customer_id) {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("customer_id", customer_id);
    this.api.callAPI(formData, "getAllAddresses").subscribe((res) => {
      if (res.status === true) {
        //console.log("Data: ", res.data);
        this.userAddress = res.data;
        this.addressExist = true;
      } else {
        this.userAddress = [];
        this.addressExist = false;
      }
    });
  }

  //form submit function
  onFormSubmit() {
    this.myapp.spinner.show();
    this.api.callAPI(this.addForm.value, "addEditAddress").subscribe((res) => {
      if (res.status) {
        this.myapp.showSuccess(res.message);
        this.myapp.spinner.hide();

        if (this.paramActive) {
          this.router.navigate(["../../pickup"]);
        } else {
          this.addForm.reset();
          this.isMapShow = false;
          this.addForm.controls["customer_id"].patchValue(this.customer_id);
          this.getUserAddress(this.user_id, this.customer_id);
        }
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
      }
    });
  }

  editAddress(index) {
    this.isEditing = true;
    this.addressHeading = "Edit Address";
    this.addForm.patchValue({
      address_id: this.userAddress[index].address_id,
      location: this.userAddress[index].location,
      complete_address: this.userAddress[index].complete_address,
      landmark: this.userAddress[index].landmark,
      lat: this.userAddress[index].latitude,
      long: this.userAddress[index].longitude,
      address_type: this.userAddress[index].address_type,
    });
  }

  cancelEdit() {
    this.addressHeading = "Add New Address";
    this.addForm.reset();
    this.addForm.controls["customer_id"].patchValue(this.customer_id);
    this.isEditing = false;
    this.isMapShow = false;
    return false;
  }

  deleteAddress(address_id, index) {
    let conf = confirm("Do you want to delete this address?");
    if (conf) {
      const formData = new FormData();
      formData.append("address_id", address_id);
      this.api.callAPI(formData, "deleteAddress").subscribe((res) => {
        if (res.status === true) {
          //based on the index remove the values from loaded array
          this.userAddress.splice(index, 1);
        }
      });
    }
  }

  //Getor - To access the fields directly in the form in HTML
  get address_id() {
    return this.addForm.get("address_id");
  }
  get location() {
    return this.addForm.get("location");
  }
  get complete_address() {
    return this.addForm.get("complete_address");
  }
  get landmark() {
    return this.addForm.get("landmark");
  }
  get lat() {
    return this.addForm.get("lat");
  }
  get long() {
    return this.addForm.get("long");
  }
  get address_type() {
    return this.addForm.get("address_type");
  }
}
