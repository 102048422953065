import { Component, OnInit } from "@angular/core";
import { ApiService } from "../service/api.service";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.css"]
})
export class ServicesComponent implements OnInit {
  services: any = [];
  constructor(private api: ApiService) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loadServices();
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  loadServices() {
    this.api.callAPI(null, "getAllServices").subscribe(res => {
      if (res.status === true) {
        //console.log("Services: ", res.data);
        this.services = res.data;
      }
    });
  }
}
