import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {
  MatCarouselModule,
  MatCarouselSlideComponent,
} from "@ngmodule/material-carousel";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { ImageCropperModule } from "ngx-image-cropper";
import { NgImageSliderModule } from "ng-image-slider";
import { NgxStripeModule } from "ngx-stripe";

import {
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatFormFieldModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatExpansionModule,
} from "@angular/material";
import { AgmCoreModule } from "@agm/core";
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from "angularx-social-login";
import { NgOtpInputModule } from "ng-otp-input";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HomeComponent } from "./home/home.component";
import { AboutComponent } from "./about/about.component";
import { ServicesComponent } from "./services/services.component";
import { PackagesComponent } from "./packages/packages.component";
import { PricingComponent } from "./pricing/pricing.component";
import { ContactComponent } from "./contact/contact.component";
import { FaqComponent } from "./faq/faq.component";
import { HelpComponent } from "./help/help.component";
import { PolicyComponent } from "./policy/policy.component";
import { TermsComponent } from "./terms/terms.component";
import { LoginComponent } from "./user/login/login.component";
import { SignupComponent } from "./user/signup/signup.component";
import { ForgotPasswordComponent } from "./user/forgot-password/forgot-password.component";
import { OrderComponent } from "./order/order/order.component";
import { OtpVerificationComponent } from "./user/otp-verification/otp-verification.component";
import { MyProfileComponent } from "./user/my-profile/my-profile.component";
import { ResetPasswordComponent } from "./user/reset-password/reset-password.component";
import { EditProfileComponent } from "./user/edit-profile/edit-profile.component";
import { EmailPhoneVerificationComponent } from "./user/email-phone-verification/email-phone-verification.component";
import { ChangePasswordComponent } from "./user/change-password/change-password.component";
import { ManageProfileComponent } from "./user/manage-profile/manage-profile.component";
import { CurrentPhoneVerificationComponent } from "./user/change-phone/current-phone-verification/current-phone-verification.component";
import { NewPhoneVerificationComponent } from "./user/change-phone/new-phone-verification/new-phone-verification.component";
import { CurrentEmailVerificationComponent } from "./user/change-email/current-email-verification/current-email-verification.component";
import { NewEmailVerificationComponent } from "./user/change-email/new-email-verification/new-email-verification.component";
import { ManageAddressComponent } from "./user/manage-address/manage-address.component";
import { MyOrdersComponent } from "./user/my-orders/my-orders.component";
import { OrderDetailComponent } from "./user/order-detail/order-detail.component";
import { NotificationsComponent } from "./user/notifications/notifications.component";
import { BuyPackageComponent } from "./buy-package/buy-package.component";
import { MyPackagesComponent } from "./user/my-packages/my-packages.component";
import { PickupComponent } from "./order/pickup/pickup.component";
import { ReviewOrderComponent } from "./order/review-order/review-order.component";
import { ThankyouComponent } from "./order/thankyou/thankyou.component";
import { BecomePartnerComponent } from "./become-partner/become-partner.component";

//Socila Media Auth keys
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(
      "409538763186-v38kah63sbc27ggboor6072d0c43fehd.apps.googleusercontent.com"
    ),
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("738827330239866"),
  },
]);
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    PackagesComponent,
    PricingComponent,
    ContactComponent,
    FaqComponent,
    HelpComponent,
    PolicyComponent,
    TermsComponent,
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    OrderComponent,
    OtpVerificationComponent,
    MyProfileComponent,
    ResetPasswordComponent,
    EditProfileComponent,
    EmailPhoneVerificationComponent,
    ChangePasswordComponent,
    ManageProfileComponent,
    CurrentPhoneVerificationComponent,
    NewPhoneVerificationComponent,
    CurrentEmailVerificationComponent,
    NewEmailVerificationComponent,
    ManageAddressComponent,
    MyOrdersComponent,
    OrderDetailComponent,
    NotificationsComponent,
    BuyPackageComponent,
    MyPackagesComponent,
    PickupComponent,
    ReviewOrderComponent,
    ThankyouComponent,
    BecomePartnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCarouselModule.forRoot(),
    BrowserAnimationsModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatExpansionModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    ImageCropperModule,
    NgImageSliderModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCIdBkAu1rREUHekmzQFIUllpI7qBCvdOo", //AIzaSyB47x1dXaRJfSGbl-uMhd7YmIUrwstJl-E
      libraries: ["places"],
    }),
    SocialLoginModule,
    NgOtpInputModule,
    NgxStripeModule.forRoot(
      "pk_test_51HILe0HuNnRUvuNi5pY5BbsaGVhzA1n21RbqQQaGNZBBav3Q5zxpK5OXou7CU35x9ajjoxbCmAK4gxh4mdt1JQg200cB2zhlQ3"
    ),
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
