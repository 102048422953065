import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-manage-profile",
  templateUrl: "./manage-profile.component.html",
  styleUrls: ["./manage-profile.component.css"]
})
export class ManageProfileComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    window.scrollTo(0, 0);
  }
}
