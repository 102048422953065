import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider
} from "angularx-social-login";
import { SocialUser } from "angularx-social-login";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  //Variable declration
  loginForm: FormGroup;
  showBtn = true;
  emailError = false;
  phoneError = false;
  loginWith: number = 1;

  //Social media variables
  public user: SocialUser;
  public loggedIn: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //REturn to my profile in case of already login
    if (window.localStorage.getItem("webUserLogin")) {
      this.router.navigate(["../my-profile"]);
      return;
    }

    //Form Model Declaration with form validation for the fields
    this.loginForm = this.fb.group({
      login_with: ["1", [Validators.required]],
      country_code: ["+64"],
      mobile_number: [
        "",
        [
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      email: [
        "",
        [Validators.email, Validators.minLength(3), Validators.maxLength(100)]
      ],
      password: ["", [Validators.required]]
    });

    //Social Media Data receving
    this.authService.authState.subscribe(user => {
      this.user = user;
      this.loggedIn = user != null;

      //console.log("User data", this.user);

      //Call the API to register/login
      if (this.user != null) {
        this.myapp.spinner.show();
        const formData = new FormData();
        formData.append("email", this.user.email);
        formData.append("name", this.user.name);
        formData.append("sm_id", this.user.id);
        formData.append("platform", this.user.provider);
        formData.append("profile_image", this.user.photoUrl);

        this.api.callAPI(formData, "socialSignupLogin").subscribe(res => {
          if (res.status) {
            this.myapp.spinner.hide();

            //Store the user data in local storage
            window.localStorage.setItem("webUserLogin", "1");
            window.localStorage.setItem("webUserId", res.data.user_id);
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem("webCustomerId", res.data.customer_id);
            window.localStorage.setItem("loginPlatform", res.data.platform);
            window.localStorage.setItem(
              "loginUserImage",
              res.data.profile_image
            );
            window.localStorage.setItem(
              "userNotificationCount",
              res.data.notification_count
            );
            this.myapp.loginUserImage = res.data.profile_image;
            this.myapp.userNotificationCount = res.data.notification_count;

            this.myapp.userMenu = true;
            this.myapp.showDropDownMenu = false;
            this.myapp.showMenuToSMUser = false;
            this.router.navigate(["../my-profile"]);
          } else {
            this.myapp.spinner.hide();
            this.myapp.showError(res.message);
          }
        });
      }
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  checkLoginWith(type: number) {
    this.emailError = false;
    this.phoneError = false;
    this.loginWith = type;
  }

  //form submit function
  onFormSubmit() {
    let username = "";
    if (this.login_with.value == "1") {
      username = this.email.value;
      if (username == "") {
        this.emailError = true;
        return false;
      }
    }

    if (this.login_with.value == "2") {
      username = this.mobile_number.value;
      if (username == "") {
        this.phoneError = true;
        return false;
      }
    }

    this.showBtn = false;
    const formData = new FormData();

    formData.append("username", username);
    formData.append("password", this.password.value);
    formData.append("country_code", this.country_code.value);
    this.api.callAPI(formData, "login").subscribe(res => {
      if (res.status) {
        this.myapp.showSuccess(res.message);
        this.showBtn = true;

        //Store the user data in local storage
        window.localStorage.setItem("webUserLogin", "1");
        window.localStorage.setItem("webUserId", res.data.user_id);
        window.localStorage.setItem("token", res.data.token);
        window.localStorage.setItem("webCustomerId", res.data.customer_id);
        window.localStorage.setItem("loginUserImage", res.data.profile_image);
        window.localStorage.setItem(
          "userNotificationCount",
          res.data.notification_count
        );
        this.myapp.loginUserImage = res.data.profile_image;
        this.myapp.userNotificationCount = res.data.notification_count;

        this.myapp.userMenu = true;
        this.myapp.showDropDownMenu = false;

        this.router.navigate(["../my-profile"]);
      } else {
        this.myapp.showError(res.message);
        this.showBtn = true;
      }
    });
  }

  //Reset the Form via Cancel Button
  resetForm() {
    this.loginForm.reset();
  }

  //Getor - To access the fields directly in the form in HTML
  get login_with() {
    return this.loginForm.get("login_with");
  }
  get country_code() {
    return this.loginForm.get("country_code");
  }
  get mobile_number() {
    return this.loginForm.get("mobile_number");
  }
  get email() {
    return this.loginForm.get("email");
  }
  get password() {
    return this.loginForm.get("password");
  }

  //Social Media Functions
  signInWithGoogle(): void {
    console.log("gmail login");
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.authService.signOut();
  }
}
