import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "./service/api.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { Location } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  aboutUs: any = "";
  userTerms: any = "";
  vendorTerms: any = "";
  driverTerms: any = "";
  userPolicy: any = "";
  vendorPolicy: any = "";
  driverPolicy: any = "";
  contactEmail: any = "";
  contactPhone: any = "";
  services: any = [];
  playStoreLink: any =
    "https://play.google.com/store/apps/details?id=com.fluffnfold";
  appleStoreLink: any = "#";
  facebookLink: any = "https://www.facebook.com";
  twitterLink: any = "https://twitter.com";
  linkedinLink: any = "https://www.linkedin.com";
  userMenu = false;
  showDropDownMenu = false;
  loginUserImage: any = "";
  userNotificationCount: any = 0;
  cartCount: any = 0;
  showMenuToSMUser = true;
  openNavbarOnMobile = false;

  constructor(
    private router: Router,
    private api: ApiService,
    public spinner: NgxSpinnerService,
    private toastr: ToastrService,
    public route: ActivatedRoute,
    location: Location
  ) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.openNavbarOnMobile = false;
      }
    });
  }

  ngOnInit() {
    //this.loadCommonData();

    //if user is logged in, the Login/REgister menu should be hidden
    if (window.localStorage.getItem("webUserLogin")) {
      this.userMenu = true;
      this.showDropDownMenu = false;
      this.loginUserImage = window.localStorage.getItem("loginUserImage");
      this.userNotificationCount = window.localStorage.getItem(
        "userNotificationCount"
      );
    }

    //if cart is set then set the count
    if (window.localStorage.getItem("cartCount")) {
      this.cartCount = window.localStorage.getItem("cartCount");
    }

    //Hide the menus for social media users
    if (window.localStorage.getItem("loginPlatform")) {
      this.showMenuToSMUser = false;
    }
  }

  ngAfterViewInit() {
    this.loadCommonData();
  }

  //Toast Messages
  showSuccess(msg) {
    this.toastr.success(msg, "Success!");
  }
  showError(msg) {
    this.toastr.error(msg, "Oops!");
  }
  showWarning(msg) {
    this.toastr.warning(msg, "Sorry!");
  }

  loadCommonData() {
    this.api.callAPI(null, "getCommonData").subscribe(res => {
      if (res.status === true) {
        //console.log("Data: ", res.data);

        let settings = res.data.settings;
        this.aboutUs = settings.about_us;
        this.userTerms = settings.customer_terms_conditions;
        this.vendorTerms = settings.vendor_terms_conditions;
        this.driverTerms = settings.driver_terms_conditions;
        this.userPolicy = settings.customer_privacy_policy;
        this.vendorPolicy = settings.vendor_privacy_policy;
        this.driverPolicy = settings.driver_privacy_policy;
        this.contactEmail = settings.contact_email;
        this.contactPhone = settings.contact_phone;

        this.services = res.data.services;
      }
    });
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
}
