import { Component, OnInit } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import {
  FormGroup,
  FormArray,
  FormControl,
  FormBuilder,
  Validators
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/service/api.service";
import { ImageCroppedEvent } from "ngx-image-cropper";

@Component({
  selector: "app-edit-profile",
  templateUrl: "./edit-profile.component.html",
  styleUrls: ["./edit-profile.component.css"]
})
export class EditProfileComponent implements OnInit {
  isUserLogin = false;
  profileForm: FormGroup;
  profile: any = [];
  user_id: any = 0;
  customer_id: any = 0;
  imgPreviewPath = null;
  FileErrorMessageLog = false;
  imageChangedEvent: any = "";
  imageSave = false;
  imageHide = false;
  showBtn = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public myapp: AppComponent,
    private route: ActivatedRoute,
    private api: ApiService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    //Login Access Validation
    this.isUserLogin = this.api.isUserLogin();

    this.user_id = localStorage.getItem("webUserId");
    this.customer_id = localStorage.getItem("webCustomerId");
    this.getUserProfile(this.user_id, this.customer_id);

    //this.myapp.spinner.show();
    //Form Model Declaration with form validation for the fields
    this.profileForm = this.fb.group({
      country_code: ["+64", [Validators.required]],
      mobile_number: [
        "",
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10),
          Validators.pattern("^[0-9()]+$")
        ]
      ],
      first_name: [
        "",
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(50),
          Validators.pattern(/^[a-zA-Z\s]*$/)
        ]
      ],
      gender: ["", [Validators.required]],
      date_of_birth: ["", [Validators.required]],
      profile_image: [""]
    });
  }

  /*ngAfterViewChecked() {
    window.scrollTo(0, 0);
  }*/

  //function to get profile data from DB
  getUserProfile(user_id, customer_id) {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("customer_id", customer_id);
    this.api.callAPI(formData, "getMyProfile").subscribe(res => {
      if (res.status === true) {
        //console.log("Data: ", res.data);
        this.profile = res.data;

        //Patch the Item data in menuForm
        this.profileForm.patchValue({
          first_name: res.data.first_name,
          profile_image: res.data.profile_image,
          date_of_birth: res.data.dob,
          gender: res.data.gender,
          country_code: res.data.country_code,
          mobile_number: res.data.phone_number
        });

        /*if (!res.data.country_code) {
          this.profileForm.patchValue({
            country_code: "+64",
            mobile_number: ""
          });
        }*/

        this.imgPreviewPath = res.data.profile_image;
      }
    });
  }

  //on File select, put the selected file in Form model variable
  onFileSelect(event: any): void {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // console.log("files" + files);
      var fileMimes = file.type.split("/");
      var fileType = fileMimes[0];
      if (fileType === "image") {
        this.imageHide = true;
        this.imageSave = true;

        this.profileForm.get("profile_image").setValue(file);
        this.imageChangedEvent = event;
        this.FileErrorMessageLog = false;
        //Preveiw of the image only on images
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = _event => {
          this.imgPreviewPath = reader.result;
        };
        return;
      } else {
        this.FileErrorMessageLog = true;
      }
    }
  }

  imageCropped(event: ImageCroppedEvent) {
    console.log("Cropping: " + JSON.stringify(event));
    this.imgPreviewPath = event.base64;
    this.profileForm.get("profile_image").setValue(event.base64);
  }

  saveCropImg() {
    this.imageSave = false;
  }

  //form submit function
  onFormSubmit() {
    //this.showBtn = false;
    this.myapp.spinner.show();

    const formData = new FormData();
    formData.append("profile_image", this.profile_image.value);
    formData.append("name", this.first_name.value);
    formData.append("date_of_birth", this.date_of_birth.value);
    formData.append("gender", this.gender.value);
    formData.append("user_id", this.user_id);
    formData.append("customer_id", this.customer_id);
    //Pass phone in case of social media user registration or if phone not verifed
    if (this.profile.mobile_verified == "0") {
      formData.append("country_code", this.country_code.value);
      formData.append("mobile_number", this.mobile_number.value);
    }

    this.api.callAPI(formData, "updateMyProfile").subscribe(res => {
      if (res.status) {
        this.myapp.spinner.hide();
        this.myapp.showSuccess(res.message);

        //Now user can buy items
        //window.localStorage.setItem("canBuyItem", "1");

        //Replace the new image in storage
        if (res.profile_image) {
          //localStorage.removeItem("loginUserImage");
          window.localStorage.setItem("loginUserImage", res.profile_image);
          this.myapp.loginUserImage = res.profile_image;
        }

        //If mobile number is not verfied then move user to verficaiton screen
        if (this.profile.mobile_verified == "0") {
          //Store the data in local storage
          window.localStorage.setItem(
            "user_verification_id",
            res.data.user_verification_id
          );
          window.localStorage.setItem(
            "verification_type",
            res.data.verification_type
          );
          window.localStorage.setItem("otp", res.data.otp);

          this.router.navigate(["../email-phone-verification"]);
        }
      } else {
        this.myapp.spinner.hide();
        this.myapp.showError(res.message);
        //this.showBtn = true;
      }
    });
  }

  //Getor - To access the fields directly in the form in HTML
  get profile_image() {
    return this.profileForm.get("profile_image");
  }
  get first_name() {
    return this.profileForm.get("first_name");
  }
  get date_of_birth() {
    return this.profileForm.get("date_of_birth");
  }
  get gender() {
    return this.profileForm.get("gender");
  }
  get country_code() {
    return this.profileForm.get("country_code");
  }
  get mobile_number() {
    return this.profileForm.get("mobile_number");
  }
}
